export default {
  provide: function() {
    return {
      $vv: this.$v
    }
  },
  validations: {
    filter: {
      dateFrom: {
        validDate: true // aussieDateValidator
      },
      dateTo: {
        validDate: true // aussieDateValidator
      }
    }
  }
}
