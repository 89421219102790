import { TableColumn } from '@/classes'

const Columns = [
  new TableColumn('Sent To', 'SENDTO', true, 'asc', 'left'),
  new TableColumn('CC', 'CC', true, 'asc', 'left'),
  new TableColumn('Message', 'MESSAGE', true, 'asc', 'left'),
  new TableColumn('Sent Date Time', 'SENTON', true, 'desc', 'left'),
  new TableColumn('Type', 'ASSETTYPE', true, 'asc', 'center'),
  new TableColumn('Status', 'STATUS', true, 'asc', 'center')
]

export { Columns }
